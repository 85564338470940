/* Main Layout */
.main-wrapper {
  background-color: #1a1f2c;
  min-height: 100vh;
  color: #e2e8f0;
}

.page-wrapper {
  padding: 20px;
}

/* Dashboard Styles */
.career-dashboard {
  background-color: #1a1f2c;
}

.dashboard-title {
  color: #e2e8f0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Search and View Toggle */
.search-wrapper {
  background: #242b3d;
  border-radius: 8px;
}

.search-wrapper input {
  background-color: #242b3d;
  border: 1px solid #374151;
  color: #e2e8f0;
  padding: 8px 16px 8px 40px;
}

.search-wrapper input:focus {
  background-color: #242b3d;
  border-color: #4f6ef7;
  color: #e2e8f0;
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 110, 247, 0.2);
}

.search-wrapper input::placeholder {
  color: #9ca3af;
}

.view-toggle .btn {
  background-color: #242b3d;
  border-color: #374151;
  color: #9ca3af;
}

.view-toggle .btn.active {
  background-color: #4f6ef7;
  border-color: #4f6ef7;
  color: #ffffff;
}

/* Milestone Container */
.milestone-container {
  background-color: #242b3d;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid #374151;
}

.milestone-buttons {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.milestone-btn {
  background: transparent;
  border: none;
  color: #9ca3af;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s;
}

.milestone-btn.active {
  background-color: #4f6ef7;
  color: #ffffff;
}

.milestone-btn:hover:not(.active) {
  background-color: #374151;
  color: #e2e8f0;
}

/* Program List */
.program-list {
  background-color: #242b3d;
  border-radius: 12px;
  padding: 16px;
  border: 1px solid #374151;
}

.program-item {
  width: 100%;
  background: transparent;
  border: none;
  color: #9ca3af;
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  transition: all 0.2s;
}

.program-item:hover {
  background-color: #374151;
  color: #e2e8f0;
}

.program-item.active {
  background-color: #4f6ef7;
  color: #ffffff;
}

.program-icon {
  font-size: 1.25rem;
}

/* Content Card */
.content-card {
  background-color: #242b3d;
  border-radius: 12px;
  padding: 24px;
  border: 1px solid #374151;
}

/* Form Controls */
.form-control {
  background-color: #1a1f2c;
  border: 1px solid #374151;
  color: #e2e8f0;
  border-radius: 6px;
}

.form-control:focus {
  background-color: #1a1f2c;
  border-color: #4f6ef7;
  color: #e2e8f0;
  box-shadow: 0 0 0 2px rgba(79, 110, 247, 0.2);
}

/* Section Headers */
.section-header {
  color: #e2e8f0;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

/* Skill Cards */
.skill-card {
  background-color: #1a1f2c;
  border: 1px solid #374151;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
}

.skill-title {
  color: #e2e8f0;
  font-weight: 500;
  margin-bottom: 8px;
}

.skill-description {
  color: #9ca3af;
  font-size: 0.875rem;
  margin: 0;
}

/* Dropdown Icons */
.dropdown-icon {
  transition: transform 0.2s;
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #1a1f2c;
}

::-webkit-scrollbar-thumb {
  background: #374151;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

.save-btn-container {
  position: absolute;
  bottom:8%;
  right: 4%;
  z-index: 10; /* Ensure it's above other content */
}
